import React, { useCallback, useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import "react-circular-progressbar/dist/styles.css";
import { TextsProps } from "../../../database/txt.interface";
import { ProtocolosProps } from "../../../interfaces/protocolo.interface";
import { AppActions } from "../../../store/ducks/app";
import { AvaliationActions } from "../../../store/ducks/avaliation";
import { beep_curto } from "../beep";
import { beep_long_contracao } from "../beep_long_contracao";
import Configuracoes from "../Configuracoes";
import Header from "../Header";
import Progress from "../progress";
import ResultadoExecucao from "../ResultadoExecucao";
import { Body, Checklist, Conteiner, Content, Scroll } from "../styles";
import TimerRelogio from "../timer";
import RenderExerProto, { ExerProtoPros } from "./renderExerProps";

import { push } from "connected-react-router";
import moment from "moment";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { Link } from "react-router-dom";
import EditProtocol from "../../../assets/icons/editProtocol.svg";
import DeleteProtocol from "../../../assets/icons/deleteProtocol.svg";
import Timer from "../../../assets/icons/timer.svg";
import { ManageProtocolActions } from "../../../store/ducks/manage_protocols";
import { AmplitudeLog } from "../../../utils/amplitude";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { loadStorage, persistStorage } from "../../../utils/base_async_storage";
import { getEvaluatedPatients } from "../../../utils/funcs";
import { colors } from "../../../styles/colors";

export const Preenchimento = styled.div`
	height: 100%;

	transition: all 0.1s ease-out;

	background-color: #000E4B;
`;

export const Barra = styled.div`
	height: 5rem;
	width: 55rem;
	background-color: #e5e5e5;

	position: relative;

	overflow: hidden;

	border-radius: 1rem;

	.lineMin {
		position: absolute;
		width: 0;
		height: 100%;
		border: 2px solid #000;
		transition: all 0.1s ease-out;
	}

	.lineMax {
		position: absolute;
		width: 0;
		height: 100%;
		border: 2px solid #000;
		transition: all 0.1s ease-out;
	}

	@media (max-width: 1300px) {
		height: 4rem;
		width: 50rem;
	}

	@media (max-width: 1130px) {
		height: 3.8rem;
		width: 45rem;
	}
`;

const Avaliacao: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const language = useSelector((state: any) => state.configs.language);
	const disable: boolean = useSelector(
		(state: any) => state.manage_protocol.disable_modal
	);
	const result_bluetooth = useSelector(
		(state: any) => state.configs.result_bluetooth
	);
	const questionarioSatisfacaoRespondido = useSelector((state: any)=> state.dashboard.resposta_questionario_satisfacao)


	const modo_demo: any = useSelector((state: any) => state.configs.modo_demo)

	const [timeInitiTimeAval, setTimeInitAvaliation] = useState<any>();

	const is_selected_avaliacao = useSelector(
		(state: any) => state.app.is_selected_avaliacao
	);

	const resultado_execucao = useSelector(
		(state: any) => state.app.resultado_execucao
	);


	const is_open_configuracoes = useSelector(
		(state: any) => state.app.is_open_configuracoes_exercicios
	);

	const slider_execucao = useSelector(
		(state: any) => state.app.slider_execucao
	);

	const current_repeticoes = useSelector(
		(state: any) => state.avaliation.current_repeticoes
	);
	const force_option_min = useSelector(
		(state: any) => state.avaliation.force_option_min
	);
	const start = useSelector((state: any) => state.avaliation.start);

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const force_option_max = useSelector(
		(state: any) => state.avaliation.force_option_max
	);
	const valor_ble = useSelector((state: any) => state.avaliation.valor_ble);
	const valor_obtido = useSelector((state: any) => state.avaliation.valor);

	const is_play = useSelector((state: any) => state.app.is_play_exercicios);

	const protocolo_list: ProtocolosProps = useSelector(
		(state: any) => state.app.protocolo_list
	);

	const is_valid: any = useSelector((state: any) => state.avaliation.is_valid);

	const checklist_execucao = useSelector(
		(state: any) => state.app.checklist_execucao
	);

	const result_bluetooth_hand_grip = useSelector(
		(state: any) => state.configs.result_bluetooth_hand_grip
	);
	const exercicio = useSelector((state: any) => state.app.exercicio);
	
	const calc_percent_values = useCallback((valKG: any, time: any) => {
		if (parseFloat(valKG) > parseFloat(force_option_min) && is_valid === null) {
			dispatch(AvaliationActions.set_is_valid(true));
			dispatch(AvaliationActions.set_valor(parseFloat(valKG).toFixed(1), time));
		}
		if (
			parseFloat(valKG) < parseFloat(force_option_min) &&
			is_valid === false
		) {
			dispatch(AvaliationActions.set_valor(parseFloat(valKG).toFixed(1), time));
			dispatch(AvaliationActions.set_is_valid(null));
		} else {
			dispatch(AvaliationActions.set_valor(parseFloat(valKG).toFixed(1), time));
		}
	}, [force_option_min, is_valid, valor_ble.peso ])

	function calc_percent_values_demo(valKG: any, time: any) {
		if (valKG > force_option_min) {
			dispatch(AvaliationActions.set_is_valid(true));
		}
		if (valKG < force_option_min) {
			dispatch(AvaliationActions.set_valor(parseFloat(valKG).toFixed(1), time));
			dispatch(AvaliationActions.set_is_valid(false));
		} else {
			dispatch(AvaliationActions.set_valor(parseFloat(valKG).toFixed(1), time));
		}
	}

	useEffect(() => {
		if (modo_demo) {
			if (is_valid && is_valid != null) {
				beep_curto();
				dispatch(AvaliationActions.set_current_repe(current_repeticoes + 1));
			}
		} else {
			if (is_valid) {
				beep_curto();
				dispatch(AvaliationActions.set_current_repe(current_repeticoes + 1));
				dispatch(AvaliationActions.set_is_valid(false));
			}
		}

		if (!is_valid && is_valid != null && start && start != null) {
			const fim = moment(new Date()).valueOf();
			const time_screen: any = (fim - timeInitiTimeAval) / 1000;
			if (!!time_screen) {
				dispatch(AvaliationActions.set_picos(time_screen.toFixed(1)));
			}
		}
	}, [is_valid, start]);

	useEffect(() => {
		if (start && start != null) {
			dispatch(AppActions.set_play_exercicios(true));
			setTimeInitAvaliation(moment(new Date()).valueOf());
		}
	}, [start]);

	useEffect(() => {
		let demoInterval: any = null;
		let demoCounter = 0;
		if (start && start != null) {
			let time = 0;
			demoInterval = setInterval(function () {
				if (modo_demo) {
					time += 0.1;
					calc_percent_values_demo(
						20 * Math.sin((5 * demoCounter * Math.PI) / 180) + 20,
						time.toFixed(1)
					);
					demoCounter++;
				}
			}, 100);

			if (!modo_demo) {
				const fim = moment(new Date()).valueOf();
				const time_screen: any = (fim - timeInitiTimeAval) / 1000;
				if (!!time_screen) {
					calc_percent_values(valor_ble.peso, time_screen.toFixed(1));
				}
			}
		}
		if (!start && start != null) {
			clearInterval(demoInterval);
			demoCounter = 0;
		}
		return () => {
			clearInterval(demoInterval);
		};
	}, [start, valor_ble.ping]);

	const contagem_regressiva = useSelector(
		(state: any) => state.configs.contagem_regressiva
	);
	const tempo_contracao = useSelector(
		(state: any) => state.configs.tempo_contracao
	);

	const handleIsNotPlay = () => {
		dispatch(AvaliationActions.set_start(false));
		dispatch(AppActions.set_play_exercicios(false));
		dispatch(AppActions.set_resultado_execucao(true));
	};

	const handleOpenContagemExercicio = () => {
		if(exercicio_atual?.IDS_HARDWARES[0] === "1") {
			if (!result_bluetooth && !modo_demo) {
				!modo_demo && dispatch(AppActions.set_modal_conecta3(true));
			} else {
				contagem_regressiva
					? dispatch(AppActions.set_modal_contagem_exercicio(true))
					: dispatch(AvaliationActions.set_start(true));
	
				contagem_regressiva === false &&
					dispatch(AppActions.set_timer_reset(true));
			}
		}	
		if(exercicio_atual?.IDS_HARDWARES[0] === "2") {
			if (!result_bluetooth_hand_grip && !modo_demo) {
				!modo_demo && dispatch(AppActions.set_modal_conecta(true));
			} else {
				contagem_regressiva
					? dispatch(AppActions.set_modal_contagem_exercicio(true))
					: dispatch(AvaliationActions.set_start(true));
	
				contagem_regressiva === false &&
					dispatch(AppActions.set_timer_reset(true));
			}
		}
	};

	const valorContador = tempo_contracao;
	const tempoMaximo = tempo_contracao;
	const [contador, setContador] = React.useState<any>(valorContador);
	const [beepContagem, setBeepContagem]: any = useState(tempoMaximo);

	const timer_reset = useSelector((state: any) => state.app.timer_reset);

	useEffect(() => {
		if (is_play) {
			if (timer_reset) {
				setTimeout(() => {
					contador !== 0 && setContador(contador - 1);
					setBeepContagem(contador);
					if (contador === 0) {
						setContador(0);
						beepContagem !== contador && beep_long_contracao();
					}
				}, 1000);
			}
		} else if (is_play === false) {
			setContador(valorContador);
			return;
		}
		if (timer_reset === false) {
			setContador(valorContador);
			return;
		}
	}, [is_play, contador, timer_reset]);

	const timer = contador;
	const valor = tempoMaximo - timer;

	const current_exercise: ExerProtoPros = useSelector(
		(state: any) => state.avaliation.current_exercise
	);

	const progress_exers_proto = useSelector(
		(state: any) => state.avaliation.progress_exers_proto
	);

	const pico_result = useSelector((state: any) => state.avaliation.pico_result);
	const id_execucao:number = useSelector((state:any)=> state.avaliation.id_execucao)

	const [ids_execucoes, set_ids_execucoes]= useState<number[]>([])
	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	useEffect(()=> {
		if(id_execucao != null){
			set_ids_execucoes((prevState)=>[...prevState, id_execucao])
		}
	},[id_execucao])

	const finalizar = () => {
		AmplitudeLog('finalizar_execucao_protocolo')
		dispatch(AvaliationActions.reset_progress_exers_proto());
		dispatch(AvaliationActions.set_in_execution_force_max_status(false));
		setContador(valorContador);
		dispatch(AvaliationActions.set_exer_protocolos_reset());
		dispatch(AvaliationActions.reset2());
		dispatch(AvaliationActions.reset());
		dispatch(ManageProtocolActions.set_button_goback(false));
		const obj = {
			name: paciente?.USUARIO_NOME,
			value: "paciente",
		};
		dispatch(AppActions.set_open_barraSuperior(obj));
		if(!questionarioSatisfacaoRespondido && protocolo_list?.ID_USUARIO == 0){
			dispatch(AppActions.set_modal_satisfacao(true, 'Protocolos'))
		}
		dispatch(AvaliationActions.set_logs_protocols_executed(
			moment(new Date()).format('YYYY-MM-DD HH:mm:ss').toString(),
			patient?.ID_USUARIO,
			protocolo_list?.ID_PROTOCOLO,
			0,
			ids_execucoes,
		));
		dispatch(push("/paciente"));
	};

	const exercicio_atual: any = protocolo_list.PROTOCOLO_EXERCICIO.find(
		(e: any) => current_exercise?.exercice_id == e?.ID_EXERCICIO
	);

	const [picoResult, setPicoResult] = React.useState<any>(0);

	let resultMediaPico = 0;
	pico_result.map((i: any) => {
		if (i.valor > picoResult) {
			setPicoResult(i.valor);
		}
		resultMediaPico += i.valor;
	});
	resultMediaPico = resultMediaPico / pico_result.length;
	const exer_protocolo = useSelector(
		(state: any) => state.avaliation.exer_protocolo
	);

	const protocolos: ProtocolosProps[] = useSelector(
		(state: any) => state.manage_protocol.protocolos
	);

	const remove = useSelector((state: any) => state.manage_protocol.remove_play);

	const ID_PROTOCOL = protocolos.map((item) => item.ID_PROTOCOLO);

	const handleProtocoloEditar = (protocolo: any) => () => {
		dispatch(AppActions.set_open_protocolo(protocolo, true));
	};

	const handleOpenExcluirProtocolo = (id: any) => () => {
		for (let i = 0; i < id.length; i++) {
			const obj = {
				mode: "protocolo",
				id: id[i],
			};
			dispatch(AppActions.set_modal_excluir({ status: true, parameter: obj }));
		}
	};

	useEffect(()=>{
		dispatch(DashBoardActions.set_questionario_satisfacao_verificar( protocolo_list?.ID_PROTOCOLO,'PROTOCOLOS'))
		
	},[protocolo_list?.ID_PROTOCOLO])
	return (
		<Conteiner>
			<Scroll>
				<Content>
					{checklist_execucao ? (
						<Checklist
							style={{ height: remove ? "150%" : "100%", width: "100%" }}
						>
							<div className="HeaderLista">
								{progress_exers_proto.length === exer_protocolo.length ? (
									<>
										<h2>{current_language?.parabens_protocolo_finalizado}</h2>
										<span>
											{current_language?.idioma === "Português"
												? protocolo_list?.PROTOCOLO_TITULO_PT
												: current_language?.idioma === "English"
												? protocolo_list?.PROTOCOLO_TITULO_EN
												: protocolo_list?.PROTOCOLO_TITULO_ES}
										</span>
										
									</>
								) : (
									<>	
										{protocolo_list?.ID_USUARIO == 0
											?
												<span>
													{current_language?.idioma === "Português"
														? protocolo_list?.PROTOCOLO_TITULO_PT
														: current_language?.idioma === "English"
														? protocolo_list?.PROTOCOLO_TITULO_EN
														: protocolo_list?.PROTOCOLO_TITULO_ES}
												</span>
											:
												<h2 >
													
													<div className="container">
														<div className="containerLeft"></div>
														<div className="containerTitle">
															{protocolo_list?.PROTOCOLO_TITULO_PT}
														</div>
														<div className="containerIcons">
															<div
																className="iconEditProtocol"
																onClick={handleProtocoloEditar(protocolo_list)}
															>
																<div>
																	<Link
																		to="adicionar_protocolo"
																		style={{
																			alignItems: "center",
																			justifyContent: "center",
																		}}
																	>
																		<img src={EditProtocol} />
																	</Link>
																</div>
															</div>
															<div
																className="iconDeleteProtocol"
																onClick={handleOpenExcluirProtocolo(ID_PROTOCOL)}
															>
																<img src={DeleteProtocol} />
															</div>
														</div>
													</div>
													
												</h2>
										}
										{disable ? (
											<div></div>
										) : (
											protocolo_list?.ID_USUARIO == 0 &&
											<div className="containerTitleSugeri">
												<img src={Timer} alt="" style={{marginRight: 4}}/>
												<p className="TempoEstimado">
													{current_language?.tempo_estimado}:
												</p>
												<p className="valueTime">{protocolo_list?.PROTOCOLO_TEMPO_ESTIMADO}</p>
											</div>
										)}
									</>
								)}
							</div>
							<div className="lista">
							<div className="exercicios">
									{protocolo_list.PROTOCOLO_EXERCICIO.map((exer, index) => {
										return (
											<>
												<RenderExerProto item={exer} index={index} />
												<hr />
											</>
										);
									})}
								</div>
								<div
									className={
										progress_exers_proto.length === exer_protocolo.length
											? `footer2`
											: `footer`
									}
								>
									{progress_exers_proto.length === exer_protocolo.length ? (
										<button className="finalizar" onClick={finalizar}>
											{current_language?.finalizar}
										</button>
									) : remove ? (
										<div></div>
									) : (
										progress_exers_proto.length > 0 
										?
											<button onClick={()=> {finalizar(), getEvaluatedPatients(paciente)}}>
												{current_language?.finalizar_execucao}
											</button>
										: <div></div>
									)}
								</div>
							</div>
						</Checklist>
					) : resultado_execucao ? (
						<ResultadoExecucao />
					) : (
						<>
							{is_open_configuracoes ? (
								<Configuracoes />
							) : (
								<>
									<Header />
									<Body>
										<h2 className="titleH2">
											{language === "PT"
												? exercicio_atual?.EXERCICIO_TITULO_PT
												: language === "EN"
												? exercicio_atual?.EXERCICIO_TITULO_EN
												: exercicio_atual?.EXERCICIO_TITULO_ES}{" "}
											({current_exercise.title})
										</h2>
										<div className="marginTopoBarra">
											<Progress
												step={valor_obtido}
												max={force_option_max * 1.2}
											/>
										</div>
										<div className="timers">
											<div className="contador">
												<div className="circularProgress">
													<CircularProgressbar
														value={valor}
														minValue={0}
														maxValue={tempoMaximo}
														strokeWidth={10}
														styles={buildStyles({
															strokeLinecap: "butt",
															pathColor: colors.neutral_grey_k_10,
															textSize: "40px",
															textColor:  colors.neutral_grey_k_10,
															trailColor: colors.primary_blue_k_light_1,
														})}
													></CircularProgressbar>
													<div className="timer">{timer}</div>
												</div>
												<p>{current_language?.contracao_tempo}</p>
											</div>
											<div className="repeticoes">
												<p>{current_repeticoes}</p>
												<span>{current_language?.config_aval_repeticoes}</span>
											</div>
											<div className="cronometro">
												<svg
													width="36"
													height="36"
													viewBox="0 0 36 36"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M17.985 3C9.705 3 3 9.72 3 18C3 26.28 9.705 33 17.985 33C26.28 33 33 26.28 33 18C33 9.72 26.28 3 17.985 3ZM18 30C11.37 30 6 24.63 6 18C6 11.37 11.37 6 18 6C24.63 6 30 11.37 30 18C30 24.63 24.63 30 18 30Z"
														fill="#4585FF"
													/>
													<path
														d="M18.75 10.5H16.5V19.5L24.375 24.225L25.5 22.38L18.75 18.375V10.5Z"
														fill="#4585FF"
													/>
												</svg>
												<TimerRelogio />
											</div>
										</div>
										{is_play ? (
											<>
												<button
													className={`pause ${ "marginTopoBarra"}`}
													onClick={()=> {
														handleIsNotPlay() 
														AmplitudeLog('parar_protocolo')
													}}
												>
													<svg
														width="14"
														height="16"
														viewBox="0 0 14 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<rect width="5" height="16" fill="#000E4B" />
														<rect x="9" width="5" height="16" fill="#000E4B" />
													</svg>
													{current_language?.parar}
												</button>
											</>
										) : (
											<>
												<button
													className={ "marginTopoBarra"}
													onClick={()=> {
														handleOpenContagemExercicio()
														AmplitudeLog('iniciar_protocolo')
													}}
												>
													<svg
														width="17"
														height="16"
														viewBox="0 0 17 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M15.555 6.90775L2.34542 0.302956C2.16722 0.220392 1.9712 0.183711 1.77519 0.196251C1.57919 0.208791 1.38944 0.270154 1.22321 0.374754C1.05698 0.479355 0.919553 0.62387 0.823436 0.795146C0.72732 0.966421 0.675568 1.15902 0.672892 1.3554V14.5593C0.671227 14.7609 0.721594 14.9595 0.819124 15.1359C0.916653 15.3124 1.05805 15.4607 1.22964 15.5665C1.40123 15.6723 1.59722 15.7321 1.79866 15.74C2.0001 15.7479 2.20018 15.7037 2.37956 15.6118L15.555 9.00696C15.7502 8.90898 15.9143 8.75867 16.0289 8.57282C16.1436 8.38697 16.2043 8.17289 16.2043 7.95451C16.2043 7.73613 16.1436 7.52206 16.0289 7.3362C15.9143 7.15035 15.7502 7.00004 15.555 6.90207V6.90775Z"
															fill="white"
														/>
													</svg>
													{current_language?.iniciar}
												</button>
											</>
										)}
									</Body>
								</>
							)}
						</>
					)}
				</Content>
			</Scroll>
		</Conteiner>
	);
};

export default Avaliacao;
